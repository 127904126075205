(function(){

var dkblue = '#0a1b33';
var ltblue = '#86D9CF';
var pink = '#AB1750';
var green = '#A1D91F';

var spring=0.1;
var friction =0.8;


function Voxel(obj){
	// obj{color, scale}
	/*********************************
	
			.pt1  	.pt2


		.pt6    .pt7		.pt3


			.pt5     .pt4

	********************************/
	var sc = typeof obj.stroke !== 'undefined' ? obj.stroke : '#ffffff'; 
	var fc = typeof obj.color !== 'undefined' ? obj.color : '#ffffff';
	var pos = typeof obj.pos !== 'undefined' ? obj.pos : new Point(0,0);
	var alpha = typeof obj.alpha !== 'undefined' ? obj.alpha : 0.01;
	this._GD = typeof obj.gd !== 'undefined' ? obj.gd : 10;
	this._GH = typeof obj.gh !== 'undefined' ? obj.gh :8.66025;

	this.minDist = typeof obj.minDist !== 'undefined' ? obj.minDist :100;

	var fca = new Color(sc);
	fca.convert('rgb');
	fca.alpha = alpha;
	
	var hover = new Color(sc);
	hover.convert('rgb');
	hover.alpha = 0.1;

	this.vx = 0;
	this.vy = 0;
	this.mass= 0;
	this.ty=pos.y;
	this.tx=pos.x;
	this.x = pos.x;
	this.y = pos.y;
	this.oPos = pos;

	this.pt1 = new Point(0,0);
	this.pt2 = new Point(this._GD, 0);
	this.pt3 = new Point(this._GD*1.5, this._GH);
	this.pt4 = new Point(this._GD, this._GH*2);
	this.pt5 = new Point(0, this._GH*2);
	this.pt6 = new Point(-this._GD*0.5, this._GH);
	this.pt7 = new Point(this._GD*0.5, this._GH);

	if(obj.top){
		hover.alpha = obj.top;
		this.path1 = new Path({
		    segments: [[this.pt1], [this.pt2], [this.pt3], [this.pt7]],
		    strokeColor: sc,
		    fillColor: hover,
		    strokeWidth :obj.top,
		    closed: true
		});
	}else{
		this.path1 = new Path({
		    segments: [[this.pt1], [this.pt2], [this.pt3], [this.pt7]],
		    strokeColor: sc,
		    //fillColor: fca,
		    strokeWidth :0.1,
		    closed: true
		});
	}
	
	this.path2 = new Path({
	    segments: [[this.pt7], [this.pt3], [this.pt4], [this.pt5]],
	    strokeColor: sc,
	    strokeWidth :0.1,
	    closed: true
	});

	this.path3 = new Path({
	    segments: [[this.pt1], [this.pt7], [this.pt5], [this.pt6]],
	    strokeColor: sc,
	    strokeWidth :0.1,
	    closed: true
	});

	this.path4 = new Path({
	    segments: [[this.pt1], [this.pt2], [this.pt3], [this.pt4], [this.pt5], [this.pt6]],
	    fillColor: fca,
	    closed: true,
	    //visible:false
	});
	this.group = new Group({
				    children: [
				        this.path1,this.path2,this.path3,this.path4
				    ],
				    pivot:new Point(this.pt7)
	});

	this.group.onMouseEnter = function(e){	}
	this.group.onMouseLeave = function(e){  }
}

Voxel.prototype ={
	position : function(pt){
		this.group.position = pt;
		this.x = pt.x;
		this.y = pt.y;
	},
	bounce: function(mousePos){
		// sets new Target
		var m = mousePos;
		var dx = this.x - m.x;
		var dy = this.y - m.y;
		var dist = Math.sqrt(dx*dx + dy*dy);
		if(dist < this.minDist){
			this.tx = this.x + dx;
			this.ty = this.y + dy;

		}else{
			this.tx = this.oPos.x;
			this.ty = this.oPos.y;
		}

	},
	slide: function(mousePos){

		var m = mousePos;
		var dx = this.oPos.x - m.x;
		var dy = this.oPos.y - m.y;
		var dist = Math.sqrt(dx*dx + dy*dy);
		if(dist < this.minDist){

			var pct = 1- dist/this.minDist;
			//needs angle on top
			var vector = new Point(this._GD/2, this._GH);
				vector.angle +=7.5;
				vector.length *=pct*5;

			var dtx = this._GD/2;
			var dty = this._GH;
			var tx = vector.x;
			var ty = vector.y;
			
			if(dy<0){
				this.tx = this.oPos.x - tx;
				this.ty = this.oPos.y + ty;
			}
			else{
				this.tx = this.oPos.x + tx;
				this.ty = this.oPos.y - ty;
			}
		}else{
			this.tx = this.oPos.x;
			this.ty = this.oPos.y;
		}
	},
	move: function(){
		// moves
		this.vx += (this.tx - this.x)*spring;
		this.vy += (this.ty - this.y)*spring;

		this.vx *= friction;
		this.vy *= friction;
		this.position(new Point(this.x +this.vx, this.y+this.vy));
	}
}

function Ball(obj){
	var r = typeof obj.radius !== 'undefined' ? obj.radius : 5;
	var c = typeof obj.color !== 'undefined' ? obj.color : '#ffffff';
	var sc = typeof obj.stroke !== 'undefined' ? obj.stroke : ltblue;
	var pt = typeof obj.pt !== 'undefined' ? obj.pt : new Point(0,0);
	var pos = typeof obj.pos !== 'undefined' ? obj.pos : new Point(0,0);

	this.vx = 0;
	this.vy = 0;
	this.mass= 0;
	this.ty=pos.y;
	this.tx=pos.x;
	this.x = pos.x;
	this.y = pos.y;
	this.oPos = pos;

	this.circle = new Path.Circle({
		radius:r,
		fillColor:c,
		center: pt,
		//strokeColor: sc,
		position: pos
	});
}
Ball.prototype={
	onMouseDown : function(){
		console.log('OMG', this.circle);
		//e.target.position += new Point(0, -20); 
	},
	position : function(pt){
		this.circle.position = pt;
		this.x = pt.x;
		this.y = pt.y;
	},
	pulse : function(sin){
		//sinkurve als alpha input
		this.circle.fillColor.alpha = (sin+1)/2;
	},
}

Grid = function(obj){
	var iMap =	[	[0,0,0,1,0, 1,0,0,1,0, 1,1,1,0,1, 1,1,0,1,1, 1],
				[0,0,0,1,0, 1,0,0,1,0, 0,1,0,0,1, 0,0,0,1,0, 1],
				[0,0,0,1,0, 1,1,0,1,0, 0,1,0,0,1, 1,1,0,1,1, 1],
				[0,0,0,1,0, 1,0,1,1,0, 0,1,0,0,1, 0,0,0,1,1, 0],
				[0,0,0,1,0, 1,0,0,1,0, 0,1,0,0,1, 1,1,0,1,0, 1],

				[0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0],
			
				[0,1,0,0,0, 1,1,0,1,1, 1,0,1,0,1, 0,1,0,1,1, 1],
				[1,0,1,0,1, 0,0,0,0,1, 0,0,1,0,1, 0,1,0,1,0, 0],
				[1,0,1,0,1, 0,0,0,0,1, 0,0,1,0,1, 0,1,0,1,1, 1],
				[1,1,1,0,1, 0,0,0,0,1, 0,0,1,0,1, 0,1,0,1,0, 0],
				[1,0,1,0,0, 1,1,0,0,1, 0,0,1,0,0, 1,0,0,1,1, 1],
				];


	var scale =1;
	this._GH = 8.66025;//*scale;
	this._GD = 10;//*scale;

	this.particles = new Array();
	this.voxels = new Array();

	this.distract;

	//var group;
	this.pausing = true;

	this.type = typeof obj.type !== 'undefined' ? obj.type : 'plane';
	this.text = typeof obj.text !== 'undefined' ? obj.text : iMap;

	var angle = 7.5*Math.PI/180;
	var rows = this.text.length;
	var cols = this.text[0].length;
	var OFF = view.bounds.width/100;
	switch (this.type) {
		case 'map':
			this.distract = 'bounce';
			//make it fit!!!

			scale = (view.bounds.width-2*OFF)/((Math.cos(angle)* rows*this._GH)+ (Math.cos(angle)*cols*this._GD));
			//console.log(scale, OFF, view.bounds.width, Math.sin(angle)* rows*this._GH);
			mouseDelta = 50;
			this.minDist= mouseDelta * scale;
			this._GH = 8.66025 * scale;
			this._GD = 10 * scale;
			this.setUpVoxelMap(this.text);
			
			break;
		default:
			this.distract = 'slide';
			rows = 10;
			cols = 10;
			scale = (view.bounds.width-2*OFF)/((Math.cos(angle)* rows*this._GH)+ (Math.cos(angle)*cols*this._GD));
			mouseDelta = 50;
			this.minDist= mouseDelta * scale;
			this._GH = 8.66025 * scale;
			this._GD = 10 * scale;
			this.setUpVoxelPlane(rows,cols);
			
	}	
}

Grid.prototype = {
	setUpVoxelPlane : function(VER, HOR){
		var ver = typeof VER !== 'undefined' ? VER : 10;
		var hor = typeof HOR !== 'undefined' ? HOR : 10;

		for(var i=ver; i>0; i--){
			for(var j=0; j< hor; j++){
				var ball = new Ball({color:ltblue,radius: 1, pt:new Point(0,0), pos: new Point(j*this._GD+i*this._GD/2, i*this._GH)});
				this.particles.push(ball);

				var voxel = new Voxel({stroke:ltblue,color: dkblue, gd: this._GD, gh: this._GH, minDist : this.minDist});
				voxel.group.position = new Point(j*this._GD+i*this._GD/2, i*this._GH);
				this.voxels.push(voxel);
			}
		}

		paper.project.activeLayer.position = view.center;
		paper.project.activeLayer.rotation = -7.5;
		
		for(i=0; i<this.particles.length; i++){
			var p =  this.particles[i];
			var v = this.voxels[i];
			v.oPos = p.oPos = p.circle.position;
			v.tx = p.tx = p.oPos.x;
			v.ty = p.ty = p.oPos.y;
			p.position(p.oPos);
			v.position(v.oPos);
		}
		this.pausing= false;
	},
	setUpVoxelMap : function(MAP){
		var map = typeof MAP !== 'undefined' ? MAP : iMap;


		for(var i=map.length-1; i>-1; i--){
			var line = map[i];
			
			for(var j=0; j< line.length; j++){
				

				if(line[j] === 1){
					var ball = new Ball({color:'#fff',radius: 1, pt:new Point(0,0), pos: new Point(j*this._GD+i*this._GD/2, i*this._GH)});
					this.particles.push(ball);
					var voxel = new Voxel({stroke:'#fff', color: dkblue, alpha: 0.1, top:0.8, gd: this._GD, gh: this._GH, minDist : this.minDist});
					voxel.group.position = new Point(j*this._GD+i*this._GD/2, i*this._GH);
					this.voxels.push(voxel);
				}
			}
		}

		paper.project.activeLayer.position = view.center;
		paper.project.activeLayer.rotation = -7.5;
		
		for(i=0; i<this.particles.length; i++){
			var p =  this.particles[i];
			var v = this.voxels[i];
			v.oPos = p.oPos = p.circle.position;
			v.tx = p.tx = p.oPos.x;
			v.ty = p.ty = p.oPos.y;
			p.position(p.oPos);
			v.position(v.oPos);
		}

		this.pausing= false;
	},
	onMouseMove : function(e){
		for(var i=0; i<this.voxels.length; i++){
			var p = this.voxels[i];
			var m =  e.point;
			
			switch (this.distract){
				case 'bounce':
					p.bounce(m);
					break;
				default:
					p.slide(m);
			}
		}
	},
	onDeviceMove: function(e){
		// figure out ball pos according to angles
		// TODO Take current gyroskope into account, it feels very 'touchy' without
		var orientation = e.o; //0 == Portrait;
		var currentView = e.v;
		if(e.o == 0){
			var verPct = 1- Math.round(e.b-90)/-180;
			var horPct = Math.round(e.g+90)/180;
		}else if(e.o == 90){
			verPct = 1- Math.round(e.g+90)/180;
			horPct = 1- Math.round(e.b-90)/-180;
		}else{
			verPct = 1- Math.round(e.g+90)/180;
			horPct =  Math.round(e.b-90)/-180;
		}
		
		
		var mX = currentView.bounds.width * horPct;
		var mY = currentView.bounds.height * verPct;
		var pt = new Point(mX, mY);
		//$('#dlog').text('g:'+e.g+',b:'+e.b+',pt:'+pt+',w:'+currentView.bounds.width+',h:'+currentView.bounds.height+',o:'+e.o);
		this.onMouseMove({point: pt});
		return {hor: horPct, ver: verPct};
	},
	onFrame: function(e){

		if(!this.pausing){
			for(var i=0; i<this.voxels.length; i++){
				var sinus = Math.sin(e.time+i*0.5);
				var voxel = this.voxels[i];
				voxel.move();
				this.particles[i].pulse(sinus);
			}	
		}	
	},
	onResize: function(event){
		
		console.log(view.bounds);
		this.pausing = true;
		project.activeLayer.size = view.size;
		project.activeLayer.position = view.center;
		for(i=0; i<this.voxels.length; i++){
			//redraw each Voxel and Particle
			var p =  this.particles[i];
			var v = this.voxels[i];
			v.oPos = p.oPos = v.group.position;
			v.tx = p.tx = v.oPos.x;
			v.ty = p.ty = v.oPos.y;
			p.position(p.oPos);
			v.position(v.oPos);
		}
		this.pausing = false;
	}
}

/*************************************************************
************** INIT STUFF ************************************
++***********************************************************/


}).call(this);