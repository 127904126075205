var navbarBg = false;
var autoScroll = false;
var garden;
var ppVisible = true;
var validEmail = true;
var sections =[];
var parallax =[];
var autoScroll= false;
var currentSection ='';
var currentPosition=0;
var sectionSwitch = 99;
var scenes =[];
var contact = false;

var voxelPaperVisible = true;
var aboutPaperVisible = false;
var interactivePaperVisible = false;
var motionPaperVisible = false;
var graphicsPaperVisible = false;

//var canvas;
var im_mousePos;
var alpha;
var beta;
var gamma;
var orientation;
var mobile = false;

var grid, iGrid, mGrid, gGrid;



var mMap = [	[1,0,0,0,1, 0,0,1,1,0, 0,1,1,1,0, 1,0,0,1,1, 0,0,1,0,0, 1],
				[1,1,0,1,1, 0,1,0,0,1, 0,0,1,0,0, 1,0,1,0,0, 1,0,1,1,0, 1],
				[1,0,1,0,1, 0,1,0,0,1, 0,0,1,0,0, 1,0,1,0,0, 1,0,1,0,1, 1],
				[1,0,0,0,1, 0,1,0,0,1, 0,0,1,0,0, 1,0,1,0,0, 1,0,1,0,0, 1],
				[1,0,0,0,1, 0,0,1,1,0, 0,0,1,0,0, 1,0,0,1,1, 0,0,1,0,0, 1],
				];

var gMap = [	[0,1,1,0,0, 1,1,1,0,0, 1,0,0,1,1, 1,0,1,0,1, 0,1,0,0,1, 1,0,1,1,1],
				[1,0,0,0,0, 1,0,1,0,1, 0,1,0,1,0, 1,0,1,0,1, 0,1,0,1,0, 0,0,1,0,0],
				[1,0,1,1,0, 1,0,1,0,1, 0,1,0,1,1, 1,0,1,1,1, 0,1,0,1,0, 0,0,1,1,1],
				[1,0,0,1,0, 1,1,0,0,1, 1,1,0,1,0, 0,0,1,0,1, 0,1,0,1,0, 0,0,0,0,1],
				[0,1,1,0,0, 1,0,1,0,1, 0,1,0,1,0, 0,0,1,0,1, 0,1,0,0,1, 1,0,1,1,1],
				];

var iMap =	[	[0,0,0,1,0, 1,0,0,1,0, 1,1,1,0,1, 1,1,0,1,1, 1,0, 0,1,0,0,0, 1,1,0,1,1, 1,0,1,0,1, 0,1,0,1,1, 1],
				[0,0,0,1,0, 1,1,0,1,0, 0,1,0,0,1, 0,0,0,1,0, 1,0, 1,0,1,0,1, 0,0,0,0,1, 0,0,1,0,1, 0,1,0,1,0, 0],
				[0,0,0,1,0, 1,0,1,1,0, 0,1,0,0,1, 1,1,0,1,0, 1,0, 1,0,1,0,1, 0,0,0,0,1, 0,0,1,0,1, 0,1,0,1,1, 1],
				[0,0,0,1,0, 1,0,0,1,0, 0,1,0,0,1, 0,0,0,1,1, 0,0, 1,1,1,0,1, 0,0,0,0,1, 0,0,1,0,1, 0,1,0,1,0, 0],
				[0,0,0,1,0, 1,0,0,1,0, 0,1,0,0,1, 1,1,0,1,0, 1,0, 1,0,1,0,0, 1,1,0,0,1, 0,0,1,0,0, 1,0,0,1,1, 1],
				];




$( window ).resize(function(e) {
  //fitCarousel();
  //sortProjects();
  fitProjects();
  fitPapers(e);
});

$(window).load(function(){
	//fitCarousel();
	// find and replace images
	var wWidth = $(this).width();
	//
	$('.carousel').find('img').each(function(){

		var imgsrc = $(this).attr('src');
		if(wWidth<481){
			imgsrc = imgsrc.replace('_full.', '_sm.');
		}
		if(wWidth<481 && $(this).hasClass('portrait')){
			imgsrc = imgsrc.replace('_sm.', '_portrait_sm.');
		}

		$(this).attr({'src': imgsrc});
	});
	

});


$(document).ready(function () {
	if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
		mobile = true;
	}
    $("button#submit-btn").click(function(event){
    	event.preventDefault();
		// Assign handlers immediately after making the request,
		// and remember the jqxhr object for this request
		var jqxhr = $.post( "assets/php/mail/mail.php", 
							{	
								email:$('#email').val(), 
								subject: $('#subject').val(),
								message: $('#message').val()})
		  .done(function(data) {
		    var obj = jQuery.parseJSON( data );
			//console.log(obj.type);
			handleFormResponse(obj);
		  })
		  .fail(function(response) {
		    console.log( "error", response );
		    console.log(response.msg);
			$('#imogra-message').hide();
			$('#responder p').text('Zur Zeit können keine Emails versendet werden.');
			$('#responder').show();
			  })
		  .always(function(response) {
		    
		}, 'json');
		 
		// Perform other work here ...
		 
		// Set another completion function for the request above
		jqxhr.always(function(msg) {
		  //console.log( "second finished", msg );
		});

    });
	sections = $('.taggable');
	parallax = $('.parallax');
	
	setUpScenes();

	setUpContactForm();

	fitProjects();

	setUpVoxelPaper();

	doParallaxScroll();


	if($(window).scrollTop()< (2*$(window).height()/3)){
		$('.navbar').addClass('basic');
		
	}else{
		// show nav bar
		$('.navbar').removeClass('basic');
	}
});


$(window).on('scroll touchmove mousewheel', function(e){
	
	if(autoScroll === false){
		var hashTag = getCurrentSection();
		if(hashTag != currentSection){
			currentSection = hashTag;
			$('.current').removeClass('current');
			$('a[href='+hashTag+']').parent().addClass('current');
			
		}
	}else{

	}

	if(currentSection ==="#imogra" && $(window).scrollTop()< (2*$(window).height()/3)){
		//hide nav bar
		//$('.navbar').fadeOut('fast');
		$('.navbar').addClass('basic');
		
		//$('.navbar').find('img').slideUp(400, function(){});
	}else{
		// show nav bar
		$('.navbar').removeClass('basic');
		//$('.navbar').find('img').slideDown(400, function(){});
	}

	switch (currentSection){
			case "#interactive":
				interactivePaperVisible = true;
				motionPaperVisible = false;
				graphicsPaperVisible = false;
				voxelPaperVisible = false;
				$('#interactive').parent().addClass('gradient');
				$('#motion').parent().removeClass('gradient');
				$('#graphics').parent().removeClass('gradient');
				break;
			case "#motion":
				interactivePaperVisible = false;
				motionPaperVisible = true;
				graphicsPaperVisible = false;
				voxelPaperVisible = false;
				$('#interactive').parent().removeClass('gradient');
				$('#motion').parent().addClass('gradient');
				$('#graphics').parent().removeClass('gradient');
				break;
			case "#graphics":
				interactivePaperVisible = false;
				motionPaperVisible = false;
				graphicsPaperVisible = true;
				voxelPaperVisible = false;
				$('#interactive').parent().removeClass('gradient');
				$('#motion').parent().removeClass('gradient');
				$('#graphics').parent().addClass('gradient');
				break;
			case "#imogra":
				interactivePaperVisible = false;
				motionPaperVisible = false;
				graphicsPaperVisible = false;
				voxelPaperVisible = true;
				if(!mobile){
					doParallaxScroll();
				}
				break;
			default:
				interactivePaperVisible = false;
				motionPaperVisible = false;
				graphicsPaperVisible = false;
				voxelPaperVisible = false;
		}

	if((currentSection === '#interactive' || currentSection === '#motion' || currentSection === '#graphics')  &&  $('a[href=#about]').parent().hasClass('current')=== false){
		$('.current').removeClass('current');
		$('a[href=#about-imogra]').parent().addClass('current');
	}
	
});

$('canvas').on({
	mouseover : function(){
		var active = $(this).attr('id');
		switch (active){
			case "interactive":
				interactivePaperVisible = true;
				motionPaperVisible = false;
				graphicsPaperVisible = false;
				voxelPaperVisible = false;
				break;
			case "motion":
				interactivePaperVisible = false;
				motionPaperVisible = true;
				graphicsPaperVisible = false;
				voxelPaperVisible = false;
				break;
			case "graphics":
				interactivePaperVisible = false;
				motionPaperVisible = false;
				graphicsPaperVisible = true;
				voxelPaperVisible = false;
				break;
			case "imogra":
				interactivePaperVisible = false;
				motionPaperVisible = false;
				graphicsPaperVisible = false;
				voxelPaperVisible = true;
				break;
			default:
				break;
		}

		$(this).parent().addClass('gradient');
	},
	mouseout : function(){
		var active = $(this).attr('id');
		switch (active){
			case "interactive":
				interactivePaperVisible = false;
				break;
			case "motion":
				motionPaperVisible = false;
				break;
			case "graphics":
				graphicsPaperVisible = false;
				break;
			case "imogra":
				voxelPaperVisible = false;
				break;
			default:
				break;
		}
		$(this).parent().removeClass('gradient');
	}
});
function setUpScenes(){
	$('.full-width').each(function(){
		var ratio = $(this).attr('data-ratio-height') ? $(this).attr('data-ratio-height') : 1;
		$(this).css({
					'width':'100%',
					'height':$(this).width()*ratio
				});
	});	
	$('.full-height').css({'height':$(window).height()});

	$('canvas').each(function(){
		$(this).css({
			'width':$(this).parent().width(),
			'height': $(this).parent().height(),
		})
		
	});
}
function getCurrentSection(){
	//var hashTag = "#imogra";
	var offset = $(this).scrollTop()+$(window).height()/2;//2*sectionSwitch;
	var hashTag = $('.current a').attr('href');
		for(var i =0; i< sections.length; i++){
			//if in range: set hashtag
			var section = sections[i];
			var sectionId = $(section).attr('id');
			var sectionTop = $(section).offset().top;
			var sectionBottom = $(section).offset().top + $(section).height();
			if(offset >= sectionTop && offset < sectionBottom){
				hashTag = '#'+sectionId;
				//console.log(offset, hashTag);
				return hashTag;
			}
		}
	return hashTag;
}
function doParallaxScroll(){
	//console.log('should do parallax sth');
	// parallax children want to sit in their parents center
	var winH = $(window).height();
	var scrollT = $(window).scrollTop();
	$('.parallax').each(function(){
		//var availHeight = winH-scrollT;
		var deltaMax = winH/2;
		var pct = scrollT/winH;
		var offset = deltaMax*pct;

		$(this).css({
			'top': ($(this).parent().height() - $(this).height())/2 + offset
		});
	})
}

function doParallaxPositioning(to){
	var horPct = to.hor;
	var verPct = to.ver;

	$('.parallax').each(function(){
		
		//var offsetX = $(window).width()/8*horPct-$(window).width()/16;
		var offsetY = $(window).height()/8*verPct -$(window).height()/16;

		$(this).css({
			'top': ($(this).parent().height() - $(this).height())/2 + offsetY,
			//'left': ($(this).parent().width() - $(this).width())/2 + offsetX
		});
	})

}
var $root = $('html, body');
$('a').click(function() {
	
    var href = $.attr(this, 'href');
    if(href== '#impressum'){
    	return true;
    }
    $('.current').removeClass('current');
    if(href != '#contact'){
    	autoScroll = true;
    	currentSection = href;
    	$root.animate({
	        scrollTop: $(href).offset().top - sectionSwitch,
	    }, 300, function () {
	        window.location.hash = href;
	        autoScroll= false;
	    });
	    $('#dlog').text(currentSection);
    	$(this).parent().addClass('current');
    	return true;
		
  	}else{
   		toggleContactForm();
   		return false;
   }
});
$('.toggle-btn').click(function(){
	var target = $(this).attr('data-target');
	if(target){
		$(target).slideToggle();
	}
	return false;
});
function handleFormResponse(response){
	if(response.type == 'send'){
		//hide form 
		$('#imogra-message').hide();
		$('#responder p').text('Vielen Dank für Ihre Nachricht.');
		$('#responder').show();
	}else if(response.type == 'form_err'){
		if(response.email != ''){
			$('#email').addClass('err')
				.parent().find('span').fadeIn().text(response.email);
		}
		if(response.subj != ''){
			$('#subject').addClass('err')
				.parent().find('span').fadeIn().text(response.subj);
		}
		if(response.msg != ''){
			$('#message').addClass('err')
				.parent().find('span').fadeIn().text(response.msg);
		}
		if(!response.validE){
			validEmail = false;
		}
	}else if(response.type == 'mailer_err'){
		console.log(response.msg);
		$('#imogra-message').hide();
		$('#responder p').text('Die Nachricht konnte leider nicht gesendet werden.');
		$('#responder').show();
		//'Die Nachricht konnte leider nicht gesendet werden. 
		//Bitte probieren Sie es später noch einmal oder senden Sie eine Nachricht an: christin@imogra.de'
	}


}
$('#form-close').click(function(){
	
	$('#email').val('');
	$('#subject').val('');
	$('#message').val('');
	$('#imogra-message').show();
	$('#responder').hide();
	toggleContactForm();
});
$('#close-btn').click(function(){
	toggleContactForm();
});

function fitCarousel(){
	var windowHeight= $(window).height();
	var windowWidth= $(window).width();
	$('.carousel').css({'height':windowHeight});
	$('.item').css({'height':windowHeight});
	
	$('.carousel').find('img').each(function(){
		
		var myImage = new Image();
		myImage.src = $(this).attr('src');

		crop_resize(this, myImage.width, myImage.height );

	});


}

function fitPapers(e){
	var windowHeight= $(window).height();
	var windowWidth= $(window).width();
	if(windowWidth<768){
		sectionSwitch = 50;
	}else{
		sectionSwitch = 99;
	}

	$('.full-width').each(function(){
		var ratio = $(this).attr('data-ratio-height') ? $(this).attr('data-ratio-height') : 1;
		$(this).css({
					'width':'100%',
					'height':$(this).width()*ratio
				});
	});	
	$('.full-height').css({
		'height':$(window).height(),
		'width':$(window).width()
	});

	//resize all canvasses	
	$('canvas').each(function(){
		$(this).css({
			'width': $(this).parent().width(),
			'height': $(this).parent().height()
		});
	});
	
	var p = paper.getPaper();
	p.remove();
	// redraw VOXELS
	setUpVoxelPaper();
}
function crop_resize(_img, _loaderw, _loaderh){
	//to fit within parents bounds
	var _img = $(_img);
	var _theight = $(window).height();
	var _twidth = $(window).width();
	var _rw = _loaderw/_twidth;
	var _rh = _loaderh/_theight;
	var _r = _loaderw/_loaderh;

	if(_rw >= _rh){
		//use full height
		$(_img).addClass('fitheight');
		_img.css({
			height:_theight,
			width:_theight*_r,
			left: (_twidth-(_theight*_r))/2,
			top:0
		});
	}else{
		//use full width
		_img.css({
			width: _twidth,
			height: _twidth/_r,
			top:(_theight-(_twidth/_r))/2,
			left:0
		});
	}
}


function toggleContactForm(){
	$('#contact').slideToggle();
}

function fitCarouselItem(item){
	//center images
	var windowHeight= $(window).height();
	$(item).css({'marginTop':(windowHeight-item.height())/2})
}

function fitProjects(){
	//take out all m-items from proj.cols
	$(".m-item").each(function(){
		if($(this).hasClass('detail')== false){
			$(this).appendTo($('.projects'));
		}
		
	});
	var count = $(".m-item").length;
	var itemWidth = $(".proj-col").first().width();
	var availWidth = $(".projects").width();//$(window).width();
	var cols = Math.floor(availWidth/itemWidth);

	for (j=0; j<count; j++){
		var col = j%cols;
		if($(".m-item").eq(j).hasClass('detail')== false){
			$(".m-item").eq(j).appendTo($(".proj-col").eq(col));
		}	
	}
	if(cols > 1 || $('.projects').hasClass('detail-view')){
		$( ".m-item" ).addClass('popOpen');
		$( ".m-item" ).removeClass('slideOpen');
		if($('.activeProject').hasClass('detail')== false){
			$('.activeProject').find('.description').slideUp(400, function(){});
		}
			
	}else{
		$( ".m-item" ).removeClass('popOpen');
		$( ".m-item" ).addClass('slideOpen');
		$('.activeProject').find('.description').slideDown(400, function(){});
		$('#proj-detail').fadeOut(400, function(){
			$('.detail').remove();
			$("#proj-list").removeClass('scrollView');
			$('.m-item').removeClass('listItem').find('.caption').hide();
		});
	}
	$('#dlog').text(availWidth);
	//setUpProjectControls();
}

//function setUpProjectControls(){

$('.m-item').on({
	mouseenter : function(){
		if($(this).hasClass('popOpen') && ($(this).hasClass('detail') || $(this).hasClass('listItem'))===false ){
			$(this).find('.caption').slideDown(400, function(){})
		}
	},
	mouseleave : function(){
		if($(this).hasClass('popOpen') && ($(this).hasClass('detail') || $(this).hasClass('listItem'))===false ){
			$(this).find('.caption').slideUp(400, function(){})
		}	
	},
	click : function(){

		if($(this).hasClass('popOpen')){
			//Larger Screens with scollView & DetailView
			$("#proj-list").addClass('scrollView');
			$('.m-item').addClass('listItem').find('.caption').show();
			$("#proj-detail").fadeIn();
			if($("#proj-detail").children().length <2){
				$('.activeProject').removeClass('activeProject');
				$(this).addClass('activeProject');
				var clone = $(this).clone();
				clone.appendTo($("#proj-detail")).addClass('detail').find('.description').fadeIn(400, function(){});
				$("#proj-detail").find('.caption').hide();	
				//$('.detail').find('.carousel').show().carousel();
				//console.log('carousel:' , $('.detail').find('.carousel'));
			}else{
				$("#proj-detail").find('.detail').remove();
				$('.activeProject').removeClass('activeProject');
				$(this).addClass('activeProject');
				var clone = $(this).clone();
				clone.appendTo($("#proj-detail")).addClass('detail').find('.description').fadeIn(400, function(){});
				$("#proj-detail").find('.caption').hide();
				//$('.detail').find('.carousel').show().carousel();
			}
			// show carousel, if there is one
			var carousel = $('.detail').find('.carousel');

			if(carousel.length > 0){
				//change its id cause its not working after cloning
				var id = $(carousel).attr('id') + '_clone';
				var selector = '#'+id;

				$(carousel).attr({'id': id});
				$(carousel).find('li').each(function(){
					$(this).attr({'data-target': selector});
				})
				$(carousel).show();
				$(carousel).carousel();
				$(carousel).on({
					//needs this because it is a clone
					swipeleft :function(){
						$(this).carousel('next').carousel('pause');
					},
					swiperight : function(){
						$(this).carousel('prev').carousel('pause');	
					}
				});
				$('.detail').find('.img-container').hide();
			}
		}else if($(this).hasClass('slideOpen')){
			//Small Screens with slideDown DetailView
			// hide carousel, if there is one in the active view
			var carousel = $('.activeProject').find('.carousel');
			if(carousel.length > 0){
				$(carousel).carousel('pause').hide();
				$('.activeProject').find('.img-container').show();
			}

			if($(this).hasClass('activeProject')){
				$('activeProject').find('.description').slideUp(400, function(){});
				$('activeProject').removeClass('activeProject');
			}else{
				$('.activeProject').find('.description').slideUp(400, function(){});
				$('.activeProject').removeClass('activeProject');
				$(this).addClass('activeProject').find('.description').slideDown();
			}

			//show carousel if there is one in the new activeProject
			carousel = $('.activeProject').find('.carousel');
			if(carousel.length > 0){
				$(carousel).show().carousel('pause');
				$('.activeProject').find('.img-container').hide();
			}
		}
	}
});
$('#proj-detail .close-btn').click(function(){
	$('.activeProject').removeClass('activeProject');
	$('#proj-detail').fadeOut(400, function(){
		$('.detail').remove();
		$("#proj-list").removeClass('scrollView');
		$('.m-item').removeClass('listItem').find('.caption').hide();
	});
});

//}

function setUpVoxelPaper(){
	console.log('hey from setting up');
	paper.install(window);
	var canvas = document.getElementById('imograCanvas');
	var interactive_canvas = document.getElementById('interactive');
	var motion_canvas = document.getElementById('motion');
	var graphics_canvas = document.getElementById('graphics');

	paper.setup(canvas);
	grid = new Grid({type:'plane'}); //sets up grid for device
	
	paper.setup(interactive_canvas);
	iGrid = new Grid({type: 'map', text: iMap}); //sets up grid for device
	
	paper.setup(motion_canvas);
	mGrid = new Grid({type: 'map', text: mMap}); //sets up grid for device
	
	paper.setup(graphics_canvas);
	gGrid = new Grid({type: 'map', text: gMap}); //sets up grid for device

	paper.projects[0].view.onFrame = function(event){
		if(voxelPaperVisible){
			grid.onFrame(event);
		}
	}
	paper.projects[1].view.onFrame = function(event){
		if(interactivePaperVisible){
			iGrid.onFrame(event);
		}
	}
	paper.projects[2].view.onFrame = function(event){
		if(motionPaperVisible){
			mGrid.onFrame(event);
		}
	}
	paper.projects[3].view.onFrame = function(event){
		if(graphicsPaperVisible){
			gGrid.onFrame(event);
		}
	}

	
	if( mobile ) {
		window.ondeviceorientation = function(event){
			handleDeviceMove(event);
		}

		setInterval(function(){
			if(voxelPaperVisible){
				im_mousePos.v = paper.projects[0].view;
				var pctPt = grid.onDeviceMove(im_mousePos);
				doParallaxPositioning(pctPt);
			}
			if(interactivePaperVisible){
				im_mousePos.v = paper.projects[1].view;
				iGrid.onDeviceMove(im_mousePos);
			}
			if(motionPaperVisible){
				im_mousePos.v = paper.projects[2].view;
				mGrid.onDeviceMove(im_mousePos);
			}
			if(graphicsPaperVisible){
				im_mousePos.v = paper.projects[3].view;
				gGrid.onDeviceMove(im_mousePos);
			}
		}, 24);	
	}else{
		var tool = new Tool();
		tool.onMouseMove = function(e){
			if(voxelPaperVisible){
				grid.onMouseMove(e);
			}
			if(interactivePaperVisible){
				iGrid.onMouseMove(e);
			}
			if(motionPaperVisible){
				mGrid.onMouseMove(e);
			}
			if(graphicsPaperVisible){
				gGrid.onMouseMove(e);
			}
		}
	}
}



function getMousePosition() {
    var pos = mousePos;
    if (!pos) {
        // We haven't seen any movement yet
        pos = {x:$(window).width()/2 , y:$(window).height()/2};
    }
    return pos;
}

function handleDeviceMove(event){
  	alpha = Math.round(event.alpha);
	beta = Math.round(event.beta);
	gamma = Math.round(event.gamma);
	var perct = (gamma+90)/180;	
	var xPos = $(window).width()*perct;	

	im_mousePos = {
   		x: Math.round(xPos),
    	y: 10,
    	a: alpha,
    	b: beta,
    	g: gamma,//Math.round(alpha+90)/180
    	v: paper.projects[0].view, //currentview default
    	o: window.orientation
	};
}

function setUpContactForm(){
	$('input').focusin(function(){
		$(this).removeClass('err');
		$(this).parent().find('span').fadeOut();
	});
	$('textarea').focusin(function(){
		$(this).removeClass('err');
		$(this).parent().find('span').fadeOut();
	});
}

  //////////////////////////////////////////////////
 //////// STUFF ///////////////////////////////////
//////////////////////////////////////////////////

$('.carousel').on({
	swipeleft :function(){
		$(this).carousel('next').carousel('pause');	
	},
	swiperight : function(){
		$(this).carousel('prev').carousel('pause');	
	}
});

